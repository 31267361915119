



















































































































































































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingMonthlyUICalendar
  from '@/calendesk/sections/section/shared/mixins/FlexibleBookingMonthlyUICalendar'
import EmployeePicker from '@/calendesk/sections/section/shared/components/EmployeePicker.vue'
import BookingTimePicker from '@/components/BookingTimePicker.vue'
import CTimeZonesSelect from '@/components/CTimeZonesSelect.vue'
import CCalendarService from '@/components/CCalendarService.vue'
import CLocationSelect from '@/components/CLocationSelect.vue'

export default mixins(FlexibleBookingMonthlyUICalendar).extend({
  name: 'FlexibleBooking4',
  components: {
    CLocationSelect,
    CCalendarService,
    CTimeZonesSelect,
    BookingTimePicker,
    EmployeePicker
  }
})

import mixins from 'vue-typed-mixins'
import FlexibleBookingV1Calendar from '@/calendesk/sections/section/shared/mixins/FlexibleBookingV1Calendar'
import { Moment } from 'moment'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'

export default mixins(FlexibleBookingV1Calendar).extend({
  watch: {
    pickerDate (val: string) {
      const currentMonth = this.$moment(this.getStartDate, DateTimeFormats.FULL).startOf('month')
      const selectedMonth = this.$moment(val, 'YYYY-MM').startOf('month')

      if (!currentMonth.isSame(selectedMonth)) {
        this.selectedDate = selectedMonth.format(DateTimeFormats.FULL)
        this.firstDateSet = false
        this.reloadEventsDebounce()
      }
    }
  },
  computed: {
    getStartDate (): Moment {
      return this.$moment(this.selectedDate, DateTimeFormats.FULL).startOf('month')
    },
    getEndDate (): Moment {
      return this.$moment(this.selectedDate, DateTimeFormats.FULL).endOf('month')
    }
  }
})
